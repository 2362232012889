// src/components/molecules/POItemRow.js
import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { FastField, useFormikContext } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getCatalogue } from "../../api/common/catalogues";
import { getProductList } from "../../api/products";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const POItemRow = ({ index, remove }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext(); // Destructure values, touched, and errors from Formik context
  const [productOptions, setProductOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchProductOptions = async (inputValue) => {
    try {
      const res = await getProductList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setProductOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleDelete = () => {
    remove(index);
  };

  useEffect(() => {
    fetchProductOptions();
  }, []);

  return (
    <Grid container spacing={0}>
      <Grid item xs={3}>
        <Autocomplete
          size="small"
          margin="none"
          options={productOptions}
          getOptionLabel={(option) => option.code || ""}
          value={
            productOptions.find(
              (option) => option.id === values.items?.[index]?.productId
            ) || null
          }
          onInputChange={(event, value) => fetchProductOptions(value)}
          onChange={(event, value) => {
            setFieldValue(`items.${index}.productId`, value ? value.id : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                touched.items?.[index]?.productId &&
                Boolean(errors.items?.[index]?.productId)
              }
              helperText={
                touched.items?.[index]?.productId &&
                errors.items?.[index]?.productId
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.quantity`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={DropdownSelector}
          fetchFunction={() => getCatalogue("UNIT")}
          name={`items.${index}.unitId`}
          fullWidth
          size="small"
          margin="none"
        >
          <MenuItem value={0}>Unit 1</MenuItem>
          <MenuItem value={1}>Unit 2</MenuItem>
        </FastField>
      </Grid>
      <Grid item xs={3}>
        <FastField
          as={TextField}
          name={`items.${index}.concept`}
          fullWidth
          multiline
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.price`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={2}>
        <FastField
          as={TextField}
          name={`items.${index}.workOrderId`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(POItemRow);
