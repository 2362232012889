// src/components/organisms/PODetailScreen.js
import React from "react";
import { Grid, Button, Divider, Box } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PODetailHeader from "../../molecules/PurchaseOrder/PODetailHeader";
import PODetailTable from "../../organisms/PurchaseOrders/PODetailTable";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import { updatePurchaseOrder } from "../../api/purchaseOrders";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const PODetailScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openSnack } = useSnack();
  const POdata = location.state || {};
  console.log(POdata);

  const initialValues = {
    purchaseOrder: {
      ...POdata,
    },
    items:
      POdata.items.length > 0
        ? POdata.items
        : new Array(POdata.noConcepts * 1 ?? 1).fill({
            quantity: "",
            price: "",
            productId: "",
            unitId: "",
            concept: "",
            workOrderId: "",
          }),
  };

  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (values) => {
    const id = values.purchaseOrder.id;
    console.log(values);

    let payload = values;
    delete payload.purchaseOrder.id;
    try {
      const res = await updatePurchaseOrder(id, payload);
      openSnack("Órden de compra guardada exitosamente", "success");
      navigate("/purchase-orders");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  return (
    <DashboardLayout>
      <Box p={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PODetailHeader data={values.purchaseOrder} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <PODetailTable
                    items={values.items}
                    noConcepts={values.noConcepts}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </DashboardLayout>
  );
};

export default React.memo(PODetailScreen);
