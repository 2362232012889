export const percentageFormatter = {
  type: "number",
  valueFormatter: (value) =>
    value != undefined
      ? `${value.toLocaleString(undefined, { minimumFractionDigits: 4 })} %`
      : "",
};

export const currencyFormatterMXN = {
  // type: "number",
  valueFormatter: (value) =>
    value != undefined
      ? `${value.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : value,
};
