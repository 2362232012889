import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import PurchaseOrderFormV2 from "../../organisms/PurchaseOrders/PurchaseOrderFormV2";
import { Box } from "@mui/material";

export default function PONewScreen() {
  return (
    <DashboardLayout>
      <Box p={2}>
        <PurchaseOrderFormV2 />
      </Box>
    </DashboardLayout>
  );
}
