// src/components/organisms/PODetailTable.js
import React from "react";
import { Grid, Button, Icon } from "@mui/material";
import { FieldArray } from "formik";
import POItemRow from "../../molecules/PurchaseOrder/POItemRow";
import POItemCellHeader from "../../atoms/PurchaseOrders/POItemCellHeader";

const PODetailTable = ({ items }) => (
  <Grid container rowSpacing={0} columnSpacing={0}>
    <Grid item xs={3}>
      <POItemCellHeader label={"Producto"} />
    </Grid>
    <Grid item xs={1}>
      <POItemCellHeader label={"Cantidad"} />
    </Grid>
    <Grid item xs={1}>
      <POItemCellHeader label={"Unidad"} />
    </Grid>
    <Grid item xs={3}>
      <POItemCellHeader label={"Concepto"} />
    </Grid>
    <Grid item xs={1}>
      <POItemCellHeader label={"Precio unitario"} />
    </Grid>
    <Grid item xs={2}>
      <POItemCellHeader label={"Orden de trabajo"} />
    </Grid>
    <Grid item xs={1}>
      <POItemCellHeader label={"Eliminar"} color={"error.main"} />
    </Grid>
    <FieldArray name="items">
      {({ push, remove }) => (
        <>
          {items.length > 0 &&
            items.map((item, index) => (
              <Grid item xs={12} key={index}>
                <POItemRow index={index} remove={remove} />
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                push({
                  quantity: 0,
                  price: 0,
                  productId: "",
                  unitId: "",
                  concept: "",
                  workOrderId: "",
                })
              }
            >
              <Icon>add</Icon>
            </Button>
          </Grid>
        </>
      )}
    </FieldArray>
  </Grid>
);

export default React.memo(PODetailTable);
