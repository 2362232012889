import React from "react";
import { Grid, Typography, TextField, Box } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";

const formatPercentage = (value) => {
  return `${value}%`;
};

const validationSchema = yup.object({
  percentageDiscount: yup
    .number("Ingresa un número válido")
    .min(0, "El valor debe ser mayor o igual a 0")
    .max(1, "El valor debe ser menor o igual a 1")
    .required("El porcentaje de descuento es requerido"),
});

const PurchaseOrderDetailHeader = ({ data }) => {
  const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "",
    }).format(value);
  };
  const formik = useFormik({
    initialValues: {
      percentageDiscount: data.percentageDiscount,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form Values:", values);
    },
  });

  const discountValue = data.subtotal * formik.values.percentageDiscount;
  const formattedDiscount = formatCurrency(discountValue);
  const formattedTax = formatCurrency(data.tax);
  const formattedSubtotal = formatCurrency(data.subtotal);
  const formattedTotal = formatCurrency(data.total);

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Agregar Conceptos orden {data.code}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Total</Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            variant="outlined"
            label="Porcentaje Descuento"
            name="percentageDiscount"
            value={formik.values.percentageDiscount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.percentageDiscount &&
              Boolean(formik.errors.percentageDiscount)
            }
            helperText={
              formik.touched.percentageDiscount &&
              formik.errors.percentageDiscount
            }
            InputProps={{
              inputComponent: ({ value }) => formatPercentage(value),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            variant="outlined"
            label="Descuento"
            value={formattedDiscount}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            variant="outlined"
            label="Total Impuesto"
            value={formattedTax}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="Subtotal"
            value={formattedSubtotal}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="Total"
            value={formattedTotal}
            disabled
            InputProps={{
              readOnly: true,
              style: { backgroundColor: "#4caf50", color: "#fff" },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

PurchaseOrderDetailHeader.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string.isRequired,
    percentageDiscount: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export default PurchaseOrderDetailHeader;
